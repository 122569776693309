// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lpa-energy-admin-primary: mat.define-palette(mat.$indigo-palette);
$lpa-energy-admin-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$lpa-energy-admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$lpa-energy-admin-theme: mat.define-light-theme(
  (
    color: (
      primary: $lpa-energy-admin-primary,
      accent: $lpa-energy-admin-accent,
      warn: $lpa-energy-admin-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($lpa-energy-admin-theme);

@import "ngx-toastr/toastr";

:root {
  --primary-color: #002643;
  --secondary-color: #52A4D5;
  --third-color: #fff5e3;
  --white-color: #fff;
  --black-color: #181b2c;
  --grey-color: #ddd;
  --fieldtext-color: #bfbfbf;
  --icon-color: #3b3b3b;
}

html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f6f6f6;
  font-family: "Roboto", sans-serif;
}

a {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  outline: none !important;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

a:hover {
  color: #111;
}

::selection {
  background: #111;
  color: #fff;
}

::-moz-selection {
  background: #111;
  color: #fff;
}

::-webkit-selection {
  background: #111;
  color: #fff;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  margin: 0;
  padding: 0;
}

p {
  padding: 0;
  color: #555555;
  font-size: 17px;
  margin: 0 0 5px 0;
  font-weight: 300;
  line-height: 35px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: #151515;
}

input,
select,
button {
  outline: none !important;
}

body::-o-scrollbar-track {
  background: #1c2e4d;
}

body::-o-scrollbar-thumb {
  background: #023f87;
}

body::-ms-scrollbar {
  width: 8px;
}

body::-ms-scrollbar-track {
  background: #1c2e4d;
}

body::-ms-scrollbar-thumb {
  background: #023f87;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.dataSheetTable {
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;

  table {
    box-shadow: none !important;

    th {
      background: var(--primary-color) !important;
      text-align: center;
      color: #fff;
      font-weight: normal;
      font-size: 12px;
      font-weight: 500;
    }

    td {
      // text-align: center;
      font-size: 12px;
      color: #8895a0;
      font-weight: normal;
      border: 1px #e3e3e3 solid;
    }

    .mdc-data-table__header-row {
      height: 41px !important;
    }

    .mdc-data-table__row {
      height: 41px !important;
    }

    .mat-sort-header-container {
      justify-content: center !important;
    }

    .mat-sort-header-pointer-middle {
      color: #fff;
    }

    .mat-sort-header-stem {
      color: #fff;
    }

    .mat-sort-header-pointer-left {
      color: #fff;
    }

    .mat-sort-header-pointer-right {
      color: #fff;
    }
  }
}

.s_error {
  cursor: pointer;
  color: #ff0000;
  font-size: 11px;
  margin-bottom: 12px;
  margin-top: 3px;

  .s_validation {
    font-size: 11px;
  }
}

.newstyle {
  .s_error {
    cursor: pointer;
    color: #ff0000;
    font-size: 11px;
    position: relative;
    top: -70px;
    left: 0;

    .s_validation {
      font-size: 11px;
    }
  }
}

.newstyleSettings {
  .s_error {
    cursor: pointer;
    color: #ff0000;
    font-size: 11px;
    position: relative;
    top: 0px;
    left: 0;

    .s_validation {
      font-size: 11px;
    }
  }
}

.formFieldMainAll {
  margin-bottom: 23px;
  margin-right: 10px;
  height: 100%;
  input {
    width: 100%;
    border: 1px solid #f2f2f2;
    padding: 3px;
    background: transparent;
  }

  span {
    // display: block;
    color: #2c2c2c;
    font-size: 12px;
    padding-bottom: 7px;
    font-weight: normal;
    .requiredColor {
      color: #ff0000;
    }
  }

  .mdc-menu-surface.mat-mdc-select-panel {
    padding: 0px 0 !important;
  }

  .mat-mdc-select-trigger {
    border: 1px #f2f2f2 solid !important;
    padding: 5px 14px;
  }

  .isInvalid {
    border: 1px #ff0000 solid !important;
  }

  .mat-mdc-select-value-text {
    padding-bottom: 0px !important;
  }

  .mat-mdc-select-min-line {
    font-size: 11px !important;
  }

  .mat-mdc-select-arrow-wrapper {
    background: url(./assets/images/dropdown-2.png) right no-repeat !important;

    svg {
      display: none !important;
    }
  }

  .mat-mdc-select-arrow {
    width: 14px;
    height: 15px;
    position: relative;
  }

  .mat-mdc-select-min-line {
    padding-bottom: 0px;
  }

  .mat-mdc-input-element {
    border: 1px #f2f2f2 solid !important;
    resize: none;
    padding: 8px 14px;
    font-size: 11px !important;
    font-weight: normal !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before {
    border-bottom: none !important;
  }

  .mat-mdc-form-field-infix {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-height: auto !important;
  }

  .mdc-line-ripple::after {
    display: none !important;
  }

  .mdc-text-field {
    padding: 0px !important;
  }

  .mat-mdc-form-field {
    display: block !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }
}

.mat-mdc-select-panel-above .mdc-menu-surface.mat-mdc-select-panel {
  padding: 0px !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  border-radius: 9px;
}

.mat-mdc-menu-content {
  padding: 0px !important;
}

.mat-mdc-menu-item {
  border-bottom: 1px #ddd solid !important;
}

.mdc-list-item__primary-text {
  font-size: 14px !important;
  color: #404040 !important;
}

.dropMenuMain {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
}

.cdk-overlay-container {
  z-index: 1000000;
}

.noData-msg {
  text-align: center;
  display: block;
  margin: 10px;
  opacity: 0.7;
}

.browerUploadDivBorder {
  .closeMainDiv {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #002643;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    border: none;

    img {
      width: 9px;
    }
  }

  .closeMainDivSmall {
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
  }
}

.scrolled_section {
  border: 1px solid;
}

.sticky-section {
  position: fixed;
  top: 0px;
  // z-index: 100;
  margin-right: 25px;
  width: -webkit-fill-available;
  border-bottom: 1px solid #f6f6f6;
  box-shadow: 0 2px 5px 0px #f6f6f6;
}

div.mat-mdc-select-panel {
  padding: 0px !important;
}

.lightColor {
  .mat-mdc-input-element {
    background: #f5f5f5 !important;
  }
}

.ng-dropdown-panel .scroll-host {
  background: #fff;
  // box-shadow: 0px 2px 2px 1px #000;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  text-overflow: ellipsis;
  padding-block: 10px;
  // border-bottom: 1px solid;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}

.ng-select .ng-clear-wrapper {
  display: none;
}

.ng-select .ng-select-container {
  border: 1px #f2f2f2 solid !important;
  padding: 5px 14px;

  &::before {
    content: ">";
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 35%;
    rotate: 90deg;
    transform: translateY(-50%);
    font-size: 22px;
  }
}
.ng-value-container {
  align-items: center;
}
.ng-placeholder {
  font-size: 11px;
  color: #2c2c2c;
}
.ng-option {
  padding-block: 16px !important;
  padding-left: 15px;
}

.ng-dropdown-panel {
  background-color: #fff;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.dragAndDropDiv {
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    opacity: 0;
  }
}

.pdf-datasheet {
  cursor: pointer;
}
